import { createGlobalStyle } from "styled-components";
import CircularStdBookWoff2 from '../../fonts/CircularStd-Book.woff2';
import CircularStdBookWoff from '../../fonts/CircularStd-Book.woff';
import CircularStdBookTTF from '../../fonts/CircularStd-Book.ttf';

const GlobalStyles = createGlobalStyle`
    body {
        @font-face {
            font-family: 'CircularStd Book';
            src: url(${CircularStdBookWoff2}) format('woff2'),
                    url(${CircularStdBookWoff}) format('woff'),
                    url(${CircularStdBookTTF}) format('truetype');
        }

        font-family: 'CircularStd Book';
        margin: 0;
        padding: 0;
        background: ${({theme}) => theme.colors.light};
        color: ${({theme}) => theme.colors.text};
        //overflow: hidden;

        .social-share button{
            margin-right: 10px;
        }
    }
`

export default GlobalStyles