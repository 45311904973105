import { React, useRef, useLayoutEffect, useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { StyledIntro, StyledHumAngle, ImgWrap, FlexContainer, Card, StyledVideo, Container } from './styles/Humangle.styled';
import Loader from './Loader';
import gsap from 'gsap';
//import videojs from 'video.js';
import VideoJS from './libs/VideoJS';
import { Howl } from 'howler';

import { WelcomeMetadatas } from './data/HumangleData';
import Header from './Header';

const Humangle = () => {
    const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: WelcomeMetadatas.title });

    const inItem = useRef();
    const inWrap = useRef();
    const arrowBack = useRef();
    const founVid = useRef();
    const impRef = useRef();
    const tourVid = useRef();
    const hum360 = useRef();
    const intro = useRef();
    const mainCon = useRef();

    let itemSec = null;
    let currentBgM = 1;

    let bgMusicFade = 0.02;
    let bgMusic1Vol = 0.25;
    let bgMusic2Vol = 0.3;

    let voicePlay = true;

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 4000)
    }, [])

    useLayoutEffect(() => {
        const showList = gsap.utils.toArray('.show-item');
        initAnimation(showList);
    })

    function restoreMusic() {
        if (currentBgM === 1) {
            bgMusic1.fade(bgMusicFade, bgMusic1Vol, 1000);
        }
        else if (currentBgM === 2) {
            bgMusic2.fade(bgMusicFade, bgMusic2Vol, 1000);
        }
    }

    function dimMusic() {
        if (currentBgM === 1) {
            bgMusic1.fade(bgMusic1Vol, bgMusicFade, 1000);
            voicePlay = true;
        }
        else if (currentBgM === 2) {
            bgMusic2.fade(bgMusic2Vol, bgMusicFade, 1000);
            voicePlay = true;
        }
    }

    const clicks = new Howl({
        "src": [
            `${s3bucket}/audios/clicks/clicks.webm`,
            `${s3bucket}/audios/clicks/clicks.mp3`
        ],
        "sprite": {
            "back-click": [
                0,
                224.0136054421769
            ],
            "click-1-3": [
                2000,
                160.00000000000014
            ],
            "click-2-4": [
                4000,
                234.67120181405932
            ]
        }
    });

    const bgMusic1 = new Howl({
        src: `${s3bucket}/audios/welcome-to-humangle/counting-sheep.mp3`,
        html5: true,
        volume: bgMusicFade,
        onend: function () {
            currentBgM = 2;
            bgMusic2.play();

            //console.log(voicePlay);
            if (voicePlay === true) {
                setTimeout(dimMusic, 1000);
            }
        }
    });

    const bgMusic2 = new Howl({
        src: `${s3bucket}/audios/welcome-to-humangle/soaring-higher.mp3`,
        html5: true,
        volume: bgMusic2Vol,
        onend: function () {
            currentBgM = 1;
            bgMusic1.play();

            if (voicePlay === true) {
                setTimeout(dimMusic, 1000);
            }
        }
    })

    const homeTour = new Howl({
        src: `${s3bucket}/audios/welcome-to-humangle/homepage-tour.mp3`,
        html5: true,
        volume: 1,
        onend: function () {
            voicePlay = false;
            restoreMusic();
        },
        onstop: function () {
            restoreMusic();
            voicePlay = false;
        }
    })

    const threeDTour = new Howl({
        src: `${s3bucket}/audios/welcome-to-humangle/3d-tour.mp3`,
        html5: true,
        volume: 1,
        onend: function () {
            restoreMusic();
            voicePlay = false;
        },
        onstop: function () {
            restoreMusic();
            voicePlay = false;
        }
    })

    const stopBgMusic = () => {
        if (currentBgM === 1) {
            bgMusic1.fade(bgMusic1Vol, 0, 1000);
        }
        else if (currentBgM === 2) {
            bgMusic2.fade(bgMusic2Vol, 0, 1000);
        }
    }

    const startBgMusic = () => {
        if (currentBgM === 1) {
            bgMusic1.fade(0, bgMusic1Vol, 1000);
        }
        else if (currentBgM === 2) {
            bgMusic2.fade(0, bgMusic2Vol, 1000);
        }
    }

    //Initial Reveal
    function moveInItemn(e) {
        const t1 = gsap.timeline();
        t1.to(inWrap.current, { duration: 1, x: e.clientX, y: e.clientY, ease: "power4.out" })
    }

    function itemHover(e) {
        if (e.type === "mouseenter") {
            const imgSrc = `${s3bucket}/images/welcome-to-humangle/${e.target.dataset.src}.jpg`;
            const t1 = gsap.timeline();

            t1.set(inItem.current, { attr: { src: imgSrc } })
                .to(inWrap.current, { autoAlpha: 1, scale: 1 });
        } else if (e.type === "mouseleave") {
            const t1 = gsap.timeline();

            t1.to(inWrap.current, { autoAlpha: 0, scale: 0.3 })
        }
    }

    function initAnimation(itemsToAnimate) {
        itemsToAnimate.forEach(item => {
            item.addEventListener('mouseenter', itemHover)
            item.addEventListener('mouseleave', itemHover)
            item.addEventListener('mousemove', moveInItemn);

            item.addEventListener('click', delayMaxiSel);
        });
    }

    function remAnimation(itemsToRemove) {
        itemsToRemove.forEach(item => {
            item.removeEventListener('mouseenter', itemHover)
            item.removeEventListener('mouseleave', itemHover)
            item.removeEventListener('mousemove', moveInItemn);
        })
    }

    // Maximizing the boxes
    function delayMaxiSel(e) {
        //console.log(voicePlay);
        if (voicePlay === true) {
            homeTour.stop();
            restoreMusic();
        }

        clicks.play('click-2-4');

        const showList = gsap.utils.toArray('.show-item');
        remAnimation(showList);

        itemSec = e.target.dataset.src;

        gsap.delayedCall(0.5, maxiSel);
    }

    function maxiSel() {
        const t2 = gsap.timeline();
        t2.to(inWrap.current, { ease: "power1.in", height: "100%", width: "100%", x: 0, y: 0, top: 0, left: 0, boxSizing: "border-box" });

        if (itemSec === '1') {
            t2.to(founVid.current, { ease: "power1.in", autoAlpha: 1, zIndex: 2 })
        }
        else if (itemSec === '2') {
            t2.to(impRef.current, { ease: "power1.in", autoAlpha: 1 })
        }
        else if (itemSec === '3') {
            t2.to(tourVid.current, { ease: "power1.in", autoAlpha: 1, zIndex: 2 })
        }
        else if (itemSec === '4') {
            dimMusic();
            threeDTour.play();
            t2.to(hum360.current, { ease: "power1.in", autoAlpha: 1 })
        }

        t2.to(arrowBack.current, { ease: "power1.in", autoAlpha: 1 });
    }

    function backToHome() {
        const t2 = gsap.timeline();
        t2.to(arrowBack.current, { ease: "power1.out", autoAlpha: 0 });

        //console.log(voicePlay);
        if (voicePlay === true) {
            restoreMusic();
        }

        clicks.play('back-click');

        if (itemSec === '1') {
            t2.to(founVid.current, { ease: "power1.out", autoAlpha: 0, zIndex: 0 });
            playerRef.current.pause();
        }
        else if (itemSec === '2') {
            t2.to(impRef.current, { ease: "power1.out", autoAlpha: 0 })
        }
        else if (itemSec === '3') {
            t2.to(tourVid.current, { ease: "power1.out", autoAlpha: 0, zIndex: 0 });
            playerRef2.current.pause();
        }
        else if (itemSec === '4') {
            t2.to(hum360.current, { ease: "power1.out", autoAlpha: 0 });
            threeDTour.stop();
        }

        if (window.innerWidth < 780) {
            t2.to(inWrap.current, { ease: "power1.out", autoAlpha: 0, scale: 0.3, height: "100px", width: "200px", x: "50vw", y: "40vh", top: "5vh", left: "-100px" });
        } else {
            t2.to(inWrap.current, { ease: "power1.out", autoAlpha: 0, scale: 0.3, height: "200px", width: "400px", x: "50vw", y: "50vh", top: "-11vh", left: "-600px" });
        }

        const showList = gsap.utils.toArray('.show-item');
        initAnimation(showList);
    }

    // Video Functions
    const playerRef = useRef(null);
    const playerRef2 = useRef(null);

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: `${s3bucket}/videos/welcome-to-humangle/message-from-founder.mp4`,
            type: 'video/mp4'
        }, {
            src: `${s3bucket}/videos/welcome-to-humangle/message-from-founder.webm`,
            type: 'video/webm'
        }]
    };

    const videoJsOptions2 = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: `${s3bucket}/videos/welcome-to-humangle/office-walkthrough.mp4`,
            type: "video/mp4"
        }, {
            src: `${s3bucket}/videos/welcome-to-humangle/office-walkthrough.webm`,
            type: "video/webm"
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            //videojs.log('player1 is waiting');
        });

        player.on('dispose', () => {
            //videojs.log('player1 will dispose');
        });

        player.on('play', () => {
            //videojs.log('player1 is played');
            stopBgMusic();
        });

        player.on('pause', () => {
            //videojs.log('player1 is paused');
            startBgMusic();
        });
    };

    const handlePlayerReady2 = (player) => {
        playerRef2.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            //videojs.log('player2 is waiting');
        });

        player.on('dispose', () => {
            //videojs.log('player2 will dispose');
        });

        player.on('play', () => {
            //videojs.log('player2 is played');
            stopBgMusic();
        });

        player.on('pause', () => {
            //videojs.log('player2 is paused');
            startBgMusic();
        });
    };

    const startExperience = () => {
        gsap.to(intro.current, { ease: "power1.out", autoAlpha: 0.5, display: "none" });
        gsap.to(mainCon.current, { ease: "power1.in", display: "flex", delay: 0.5 });
        bgMusic1.play();
        homeTour.play();
    }

    return (
        <HelmetProvider>
            {loading ? (<Loader />)
                : (<>
                    <Helmet>
                        <title>{WelcomeMetadatas.title}</title>
                        <meta name="description" content={WelcomeMetadatas.description} />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:site" content="@HumAngle_" />
                        <meta name="twitter:creator" content="@HumAngle_" />
                        <meta name="twitter:title" content={WelcomeMetadatas.title} />
                        <meta name="twitter:description" content={WelcomeMetadatas.description} />
                        <meta name="twitter:image" content={WelcomeMetadatas.image} />
                        <meta property="og:url" content={WelcomeMetadatas.url} />
                        <meta property="og:title" content={WelcomeMetadatas.title} />
                        <meta property="og:description" content={WelcomeMetadatas.description} />
                        <meta property="og:image" content={WelcomeMetadatas.image} />
                        <meta property="og:image:alt" content={WelcomeMetadatas.title} />
                        <meta property="og:site_name" content="HumAngle" />
                    </Helmet>
                    <Header />
                    <StyledIntro ref={intro}>
                        <div>
                            <p>Welcome to HumAngle</p>
                            <span>Click below to continue</span>
                            <svg onClick={startExperience}
                                viewBox="0 0 16 16"
                                fill="currentColor"
                            >
                                <path
                                    fill="currentColor"
                                    d="M6 8H1V6h5V4l3 3-3 3zm10-8v13l-6 3v-3H4V9h1v3h5V3l4-2H5v4H4V0z"
                                />
                            </svg>
                            <span>For better experience, view on PC</span>
                        </div>
                    </StyledIntro>
                    <StyledHumAngle ref={mainCon}>
                        <ImgWrap ref={inWrap}>
                            <div>
                                <img ref={inItem} src={`${s3bucket}/images/welcome-to-humangle/1.jpg`} alt='' />
                                <svg ref={arrowBack} onClick={backToHome}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    height="1em"
                                    width="1em"
                                >
                                    <path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" />
                                </svg>
                                <StyledVideo ref={founVid}>
                                    <div><VideoJS options={videoJsOptions} onReady={handlePlayerReady} /></div>
                                </StyledVideo>
                                <Container ref={impRef}>
                                    <div>
                                        <p>HumAngle has reported on the experiences of men who were wrongfully incarcerated by the Nigerian army during the Boko Haram insurgency, especially between 2013 and 2016. These men were detained due to their affiliation with suspected terrorist groups, but many were innocent civilians who were displaced from their communities. Thousands of men were detained, but their wives refused to give up on them and formed a support group known as Knifar.</p>
                                        <p>HumAngle recognised the strength and resilience of the Knifar group and partnered with them to report on the experiences of the incarcerated men and advocate for their release. In July 2021, over a thousand of these men were finally cleared for release after up to six years in detention. While this news filled HumAngle with hope, we recognised that thousands more were still in detention.</p>
                                        <p>We continued to cover the issue and expanded our efforts to include investigating and documenting security funding in the humanitarian space in Nigeria’s northeast. We created the HumAngle Accountability Fellowship, which trained young journalists and advocates in Borno, Adamawa, and Yobe states to track and report on cases of accountability related to insecurity.</p>
                                        <p>HumAngle's reporting had an immediate impact in some cases, such as when a construction company returned to fix a faulty drainage system in a village after our report. We also partnered with OSIWA to create a missing persons dashboard and document the missing persons' crisis in the northeast, the first in-depth media coverage of the problem.</p>
                                        <p>HumAngle's reporting has also been recognised with awards and shortlists. We believe that journalism has the power to make a difference and have resolved to chase impact, no matter how difficult the task may be.</p>
                                    </div>
                                </Container>
                                <Container ref={hum360}>
                                    {/* <iframe src='https://my.matterport.com/show/?m=Xemi3qQxGLq' allowFullScreen allow='xr-spatial-tracking'></iframe> */}
                                    <iframe title='3D Tour Of HumAngle Office' src='https://my.matterport.com/show/?m=Xemi3qQxGLq&brand=0' allowFullScreen allow='xr-spatial-tracking'></iframe>
                                </Container>
                                <StyledVideo ref={tourVid}>
                                    <div><VideoJS options={videoJsOptions2} onReady={handlePlayerReady2} /></div>
                                </StyledVideo>
                            </div>
                        </ImgWrap>

                        <FlexContainer>
                            <h1>HumAngle</h1>
                            <p>Our past, present, future and impact.</p>
                        </FlexContainer>
                        <FlexContainer>
                            <Card className='show-item' data-src="1" bg='#A88640'>Message from our founder</Card>
                            <Card className='show-item' data-src="2" bg='#75E8F5'>Impact of HumAngle</Card>
                            <Card className='show-item' data-src="4" bg='#A86B62'>Explore our office in VR</Card>
                            <Card className='show-item' data-src="3" bg='#F5C869'>Video tour of our office</Card>
                        </FlexContainer>
                    </StyledHumAngle>
                </>)}
        </HelmetProvider>
    );
}

export default Humangle;