import { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { CharSVG } from '../styles/UnequalAccessAid.styled';
import {Howl} from 'howler';

const UaaCharDisplay = (props) => {
    const t1 = useRef();
    const t2 = useRef();
    const prevChapter = useRef();
    const [playState, setPlayState] = useState(0);
    const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

    const musaAudio = useRef (new Howl({
        src: `${s3bucket}/audios/unequal-access-aid/musa-tela.mp3`,
        html5: true,
        onplay: () => {
            setPlayState(2);
        },
        onend: () => {
            setPlayState(1);
        }
    }));

    const belloAudio = useRef (new Howl({
        src: `${s3bucket}/audios/unequal-access-aid/bello-hammandila.mp3`,
        html5: true,
        onplay: () => {
            setPlayState(2);
        },
        onend: () => {
            setPlayState(1);
        }
    }));

    const hawwaAudio = useRef (new Howl({
        src: `${s3bucket}/audios/unequal-access-aid/hawwa-adam.mp3`,
        html5: true,
        onplay: () => {
            setPlayState(2);
        },
        onend: () => {
            setPlayState(1);
        }
    }));

    const playAudio = () => {
        pauseAudio();

        if (['gw2', 'gw3', 'gw4', 'gw5', 'gw6'].includes(props.chapter)) {
            musaAudio.current.play();
        }
        else if (['md1','md2', 'md3', 'md4'].includes(props.chapter)) {
            belloAudio.current.play();
        }
        else if (['mk1','mk2', 'mk3', 'mk4', 'mk6'].includes(props.chapter)) {
            hawwaAudio.current.play();
        }
        setPlayState(2);
    }

    const pauseAudio = () => {
        musaAudio.current.stop();
        belloAudio.current.stop();
        hawwaAudio.current.stop();

        setPlayState(1);
    }

    function showButton(playState) {
        if (playState === 0) {
            if (t2.current) t2.current.reverse();
        }
        else if (playState === 1) {
            if (t2.current) t2.current.reverse();
            t2.current = gsap.timeline().to("#play", {autoAlpha: 1, display: 'block', duration: 0.1, ease: "power4.inOut" });
        }
        if (playState === 2) {
            if (t2.current) t2.current.reverse();
            t2.current = gsap.timeline().to("#pause", {autoAlpha: 1, display: 'block', duration: 0.1, ease: "power4.inOut" });
        }
    }

    useEffect(() => {
        showButton(playState);
    }, [playState]);

    useEffect (() => {
        const showCircle = () => gsap.to("#svg", {autoAlpha: 1, display: 'block', duration: 0.2 }, "<");
        const hideCircle = () => gsap.to("#svg", {autoAlpha: 0, display: 'none', duration: 0.2 }, "<");

        if (props.chapter === 'gw2' || props.chapter === 'gw6') {
            if (prevChapter.current === 'gw1' || prevChapter.current === 'md1') {
                if (t1.current) t1.current.reverse();
                t1.current = gsap.timeline().to("#musa-svg", {autoAlpha: 1, display: 'block', duration: 0.2 });
                if (prevChapter.current === 'gw1') showCircle();
            }

            !musaAudio.current.playing() ? setPlayState(1) : setPlayState(2);  
        }
        else if (props.chapter === 'md1' || props.chapter === 'md4') {
            if (prevChapter.current === 'gw6' || prevChapter.current === 'mk1') {
                if (t1.current) t1.current.reverse();
                t1.current = gsap.timeline().to("#bello-svg", {autoAlpha: 1, display: 'block', duration: 0.2});
            }

            !belloAudio.current.playing() ? setPlayState(1) : setPlayState(2);
        }
        else if (props.chapter === 'mk1' || props.chapter === 'mk6') {
            if (prevChapter.current === 'md4' || prevChapter.current === 'mubi1') {
                if (t1.current) t1.current.reverse();
                t1.current = gsap.timeline().to("#hawwa-svg", {autoAlpha: 1, display: 'block', duration: 0.2});
                if (prevChapter.current === 'mubi1') showCircle();
            }

            !hawwaAudio.current.playing() ? setPlayState(1) : setPlayState(2);
        }
        else if (props.chapter === 'mubi1') {
            if (t1.current) t1.current.reverse();
            if (prevChapter.current === 'mk6') hideCircle();
            setPlayState(0);
            //t1.current = gsap.timeline().to("#naomi-svg", {autoAlpha: 1, display: 'block', duration: 0.2});
        }
        else if (!props.chapter || props.chapter === 'gw1' || props.chapter === 'mubi5') {
            if (t1.current) t1.current.reverse();
            if (prevChapter.current === 'gw2') hideCircle();
            setPlayState(0);
        }

        prevChapter.current = props.chapter;
    }, [props]);

    return (
        <CharSVG>
        <svg version="1.1" id="svg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 500 500" xmlSpace="preserve">
                <image id="bello-svg" style={{ overflow: 'visible' }} width="400" height="432"
                xlinkHref="https://humangle-interactive.s3.eu-north-1.amazonaws.com/images/unequal-access-aid/markers/bello-sm.png"
                transform="matrix(1 0 0 1 50 34)"></image>
                <image id="hawwa-svg" style={{ overflow: 'visible' }} width="400" height="413"
                xlinkHref="https://humangle-interactive.s3.eu-north-1.amazonaws.com/images/unequal-access-aid/markers/hawwa-sm.png"
                transform="matrix(1 0 0 1 50 43.5)"></image>
                <image id="musa-svg" style={{ overflow: 'visible' }} width="400" height="512"
                xlinkHref="https://humangle-interactive.s3.eu-north-1.amazonaws.com/images/unequal-access-aid/markers/musa-sm.png"
                transform="matrix(0.97 0 0 0.9453 56 8)"></image>
                <image id="naomi-svg" style={{ overflow: 'visible' }} width="400" height="494"
                xlinkHref="https://humangle-interactive.s3.eu-north-1.amazonaws.com/images/unequal-access-aid/markers/naomi-sm.png"
                transform="matrix(1 0 0 1 50 3)"></image>
                <g id="pause" onClick={pauseAudio}>
                    <rect id="XMLID_2_" x="347.8" y="355.1" className="st0" width="55.8" height="103.8"></rect>
                    <rect id="XMLID_7_" x="355.6" y="366.5" className="st1" width="15.1" height="75.5"/>
                    <rect id="XMLID_14_" x="379.3" y="367.4" className="st1" width="15.1" height="75.5"/>
                </g>
                <g id="play" onClick={playAudio}>
                    <path id="XMLID_41_" className="st2" d="M356.3,342.5c2.6,1.2,5,2.8,7.5,4.3c31.3,18.6,62.5,37.2,93.8,55.8c2.2,1.3,3,3.3,2.1,5.3
                        c-0.5,1-1.3,1.6-2.3,2.1c-10.4,5.6-20.7,11.2-31.1,16.8c-22.7,12.3-45.5,24.6-68.2,36.9c-0.5,0.3-1,0.6-1.5,0.8c-0.7,0-1.4,0-2.1,0
                        c-2.1-0.7-2.9-1.8-3-4c0-0.5,0-1,0-1.4c0-37,0-74.1,0-111.1c0-0.5,0-1,0-1.4c0.1-2.3,1-3.5,3.2-4
                        C355.2,342.5,355.7,342.5,356.3,342.5z"/>
                    <path id="XMLID_39_" className="st3" d="M359.4,454.2c0-33.7,0-67.3,0-101.1c2.1,1.2,4.1,2.4,6.1,3.6c21.1,12.6,42.2,25.1,63.3,37.7
                        c6.2,3.7,12.5,7.4,18.7,11.1c0.7,0.4,0.9,0.6,0,1.1c-8.8,4.8-17.7,9.5-26.5,14.3c-15.9,8.6-31.9,17.3-47.8,25.9
                        c-4.3,2.3-8.5,4.6-12.8,6.9C360.2,453.9,359.8,454.1,359.4,454.2z"/>
                </g>
            </svg>
            </CharSVG>
        )
}

export default UaaCharDisplay;