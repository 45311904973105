const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

export const HumangleMetadatas = {
    title: "HumAngle Interactive",
    description: "HumAngle Interactive presents stories in an interactive format, offering readers multiple and innovative ways to read and experience the piece, keeping them fully engaged and invested in the story.",
    image: `${s3bucket}/images/interactive-card.jpg`,
    url: "https://interactive2.humanglemedia.com"
};

export const WelcomeMetadatas = {
    title: "HumAngle - Our past, present, future and impact",
    description: "Take a tour of HumAngle with this interactive story. Hear a word from our CEO, read about our impact, watch a video about about our staff/office and take a 3D tour through our office.",
    image: `${s3bucket}/images/welcome-to-humangle/humangle-building.png`,
    url: "https://interactive2.humanglemedia.com/welcome-to-humangle"
};