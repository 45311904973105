import { TwitterIcon, TwitterShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';

const SocialShare = (props) => {
    return (
        <div className="social-share">
            <TwitterShareButton title={props.metaData.title} url={props.metaData.url}><TwitterIcon size={32} round={false} borderRadius={10} /></TwitterShareButton>
            <FacebookShareButton quote={props.metaData.title} url={props.metaData.url}><FacebookIcon size={32} round={false} borderRadius={10} /></FacebookShareButton>
            <LinkedinShareButton title={props.metaData.title} url={props.metaData.url}><LinkedinIcon size={32} round={false} borderRadius={10} /></LinkedinShareButton>
            <WhatsappShareButton title={props.metaData.title} url={props.metaData.url}><WhatsappIcon size={32} round={false} borderRadius={10} /></WhatsappShareButton>
        </div>
    )
}

export default SocialShare;