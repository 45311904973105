import styled from "styled-components";

const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

export const Container = styled.div`
    //display: flex;

    .mapboxgl-map {
        width: 70% !important;
        height: 100vh !important;
        position: fixed !important;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        flex-direction: column;

        .mapboxgl-map {
            width: 100vw !important;
            height: 100vh !important;
        }
    }

    .popy {
            margin-top: -100px;

            &.md2-popup {
                margin-top: 130px;
                margin-left: 60px;
            }

            &.mk3-popup, &.mubi3-popup, &.mubi4-popup {
                margin-top: -140px;
            }
    }
`

export const Intro = styled.div`
    position: fixed;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 70%;
    text-align: center;
    background-color: #262626e0;
    color: #fff;

    h1, p {
        width: 80%;
    }

    .listenInstruction {
        margin-top: auto;
        border: #fff 1px solid;
        padding: 10px;
        width: fit-content;

        span {
            padding-right: 10px;
        }

        :hover {
            cursor: pointer;
            color: #3fb1ce;
        }
    }

    .deskInstruction, .mobInstruction {
        line-height: 1.4em;
        text-align: justify;
        margin-top: auto;
        font-size: 0.8em;
    }

    .mobInstruction {
        display: none;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100vw;
        z-index: 3;

        .mobInstruction {
            display: block;
        }

        .deskInstruction {
            display: none;
        }
    }
`

export const Outro = styled(Intro)`
    line-height: 1.8rem;
    font-size: 1.2rem;
    text-align: justify;
    visibility: hidden;

    a {
        color: #3fb1ce;
        text-decoration: underline;
    }

    a:hover {
        opacity: 0.8;
        text-decoration: none;
    }
`

export const Icon = styled.img`
    width: 60px;
    opacity: 0.9;
    transition: all 0.3s ease-in-out;

    &.musa-sm, &.bello-sm, &.hawwa-sm, &.naomi-sm {
        position: absolute;
        top: -50px;
        left: 30px;
        z-index: 2;
    }

    &.gw2-icon, &.gw3-icon, &.gw4-icon, &.gw5-icon, &.md3-icon, &.mk1-icon, &.mk5-icon, &.mubi5-icon {
        position: absolute;
        top: -50px;
        left: 70px;
        z-index: 1;
    }

    &.md1-icon, &.mk3-icon {
        position: absolute;
        left: -50px;
        z-index: 1;
    }

    &.md4-icon {
        width: 40px;
        position: absolute;
        left: 150px;
        z-index: 1;
    }

    &.mubi4-icon2 {
        position: absolute;
        top: 20px;
        left: 50px;
        z-index: 1;
    }
`

export const MapSideContainer = styled.div`
    width: 30%;
    margin-left: 70%;
    z-index: 2;

    section {
        padding-left: 20px;
        padding-right: 20px;
        height: 30vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #262626ad;
        color: white;

        h2 {
            font-size: 1.8rem;
            text-align: center;
        }
        
        p {
            font-size: 1.1rem;
            line-height: 1.5rem;
        }
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        width: 100vw;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 100vh;
        margin-left: 0;

        section {
            height: initial;
        }
    }
`

export const Chapter = styled.div`
    background-attachment: fixed;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 50vh;
    padding-top: 300px;
    padding-bottom: 100px;
    margin-left: 5px;
    //margin-right: 20px;
    
    &.gw {
        background-image: url(${s3bucket}/images/unequal-access-aid/fleeing-from-gwoza.jpg);
    }

    &.md {
        background-image: url(${s3bucket}/images/unequal-access-aid/attack-in-madagali.jpg);
    }

    &.mk {
        background-image: url(${s3bucket}/images/unequal-access-aid/ran-to-michika.jpg);
    }

    &.mubi {
        background-image: url(${s3bucket}/images/unequal-access-aid/displaced-from-mubi.jpg);
    }

    &.gw:nth-child(6), &.md:nth-child(10), &.mk:nth-child(16) {
        border: 1rem solid;
        border-top-style: none;
        border-right-style: none;
        border-left-style: none;
        border-color: white;
    }

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        height: 20vh;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 400px;

        &.gw, &.md, &.mk, &.mubi {
            background-image: none;
        }

        &.gw:nth-child(6), &.md:nth-child(10), &.mk:nth-child(16) {
            border: none;
        }
    }
`

export const LastChapter = styled(Chapter)`
    margin-top: 10px;
    background-image: url(${s3bucket}/images/unequal-access-aid/fleeing-from-gwoza.jpg);
    z-index: -1;

    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        background-image: none;
    }
`

export const PopupText = styled.div``

export const CharSVG = styled.div`
    width: 150px;
    position: fixed;
    z-index: 3;
    top: 10%;

    svg {
        background-color: beige;
        border-radius: 80%;
        border: #000000 solid 2px;
        display: none;
    }

    .st0{fill:#FFFFFF;}
    .st2{fill:#2C4045;}

    #hawwa-svg, #musa-svg, #bello-svg, #naomi-svg {
        display: none;
        border: #000000 solid 2px;
    }

    #pause, #play {
        display: none;
        fill: #22B2D7;

        &:hover {
            cursor: pointer;
            fill: #2C4045;
            stroke-width: 0.5;
        }
    }
`