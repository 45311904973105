import styled from "styled-components";

export const StyledLoader = styled.div`
    display: flex;
    height: 100svh;
    justify-content: center;
    align-items: center;

    img {
        width: 100px;
    }
`