const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

export const MetaDatas = {
    title: "Where Do You Run To?: A Tale Of Unequal Access To Aid For Displaced Persons In Adamawa",
    author: "Zubaida Baba Ibrahim",
    url: "https://interactive2.humanglemedia.com/unequal-access-aid",
    description: "The decade-long insurgency that originated in Borno has spilled over to communities in neighbouring Adamawa where many also fled their homes to reside in camp and camp-like settings. While the poor living conditions of these people are glaring, there has not been equal access to aid and humanitarian support for them compared to their Borno counterparts. HumAngle finds out why.",
    image: `${s3bucket}/images/unequal-access-aid/unequal-access-aid.jpg`
}; 

export const TranSections = [
    {
        id: "gw1",
        type: "h2",
        content: "Fleeing From Gwoza"
    },
    {
        id: "gw2",
        type: "p",
        content: "Musa Tela’s children were playing, his wife was turning corn flour to pap while he and his friends socialised beneath a tree when word came from their community’s chief."
    },
    {
        id: "gw3",
        type: "p",
        content: "Musa was a farmer in Gwoza Local Government Area (LGA) in Borno State, northeastern Nigeria when the decade-long insurgency started."
    },
    {
        id: "gw4",
        type: "p",
        content: "He owned a house in Madagali, a community bordering his hometown, in neighbouring Adamawa, and so he and his family sought refuge there."
    },
    {
        id: "gw5",
        type: "p",
        content: "On Aug. 1, 2014, Musa heard that some of his clansmen were in  Yola, Adamawa’s state capital, so he travelled to the town and found farmland that he started to prepare for crop cultivation."
    },
    {
        id: "gw6",
        type: "p",
        content: "After two weeks of visiting Yola, he journeyed back to Madagali."
    },
    {
        id: "md1",
        type: "h2",
        content: "The Attack In Madagali"
    },
    {
        id: "md2",
        type: "p",
        content: "Bello Hammandila was part of the people who left Gwoza for Madagali, where he also owned a house. Two months after running away for his safety, members of the insurgency followed."
    },
    {
        id: "md3",
        type: "p",
        content: "When the attack started, Bello had gone to the mosque to pray the Maghrib prayer at around 6 p.m. He said that they stayed there in fear of being ambushed and kept waiting for the attacks to end until it was time for the subsequent prayer at around 8 p.m."
    },
    {
        id: "md4",
        type: "p",
        content: "By morning, he, his brothers and some of his friends made the decision to flee. “We noted that staying here would be impossible, and we needed to leave.”\nBello and others affected fled to Yola."
    },
    {
        id: "mk1",
        type: "h2",
        content: "Ran To Michika For Safety"
    },
    {
        id: "mk2",
        type: "p",
        content: "Hawwa Adam, originally from Michika LGA of Adamawa, was a newly married woman who moved to Madagali to stay with her husband in 2014."
    },
    {
        id: "mk3",
        type: "p",
        content: "She had been confirmed a few weeks pregnant when the town was attacked."
    },
    {
        id: "mk4",
        type: "p",
        content: "But Hawwa had not completely fled from terror."
    },
    {
        id: "mk5",
        type: "p",
        content: "In Michika, members of the Boko Haram terror group carried out a rapid attack with locally made bombs, and one landed in Hawwa’s family home. She said it destroyed one of their rooms."
    },
    {
        id: "mk6",
        type: "p",
        content: "Hawwa and her family left Michika for Yola which was relatively safer. She reunited with her husband and in-laws but found that she had miscarried her baby."
    },
    {
        id: "mubi1",
        type: "h2",
        content: "Displaced From Mubi"
    },
    {
        id: "mubi2",
        type: "p",
        content: "Naomi Daudu was 19 when Boko Haram happened to Mubi, also an LGA in the north of Adamawa. Now 27, she recalls how some locals who fled from Michika trooped into Mubi with bloodied feet and some with broken limbs."
    },
    {
        id: "mubi3",
        type: "p",
        content: "On a fateful Thursday, Naomi and other residents of Mubi began to hear gunshots from their community’s marketplace, and then people started to run."
    },
    {
        id: "mubi4",
        type: "p",
        content: "Naomi ran to Wamdewo, a village in neighbouring Borno state, where the chief hosted them for a while with the assumption that the security situation would improve and those that fled would return home."
    },
    {
        id: "mubi5",
        type: "p",
        content: "Naomi has not set eyes on her parents ever since."
    }
];


export const Chapters = {
    'gw1': {
        duration: 2000,
        bearing: 0,
        center: [13.6884, 11.0897],
        zoom: 10,
        pitch: 50
    },
    'gw2': {
        duration: 2000,
        bearing: 0,
        center: [13.6885, 11.0897],
        zoom: 10,
        pitch: 50,
        icon: ["musa-sm","gw2-icon"],
        text: "“Everyone in the village and its environs should flee."
    },
    'gw3': {
        duration: 2000,
        bearing: 0,
        center: [13.6886, 11.0897],
        zoom: 14,
        pitch: 50,
        icon: ["gw3-icon"],
        text: "“By 12 p.m. sharp, we had left,” Tela says."
    },
    'gw4': {
        duration: 2000,
        bearing: 0,
        center: [13.6361, 10.8828],
        zoom: 14,
        pitch: 50,
        icon: ["musa-sm","gw4-icon"],
        text:"“But there were no lands for us to farm,”"
    },
    'gw5': {
        duration: 2000,
        bearing: 0,
        center: [12.4782, 9.2095],
        zoom: 10,
        pitch: 50,
        icon: ["musa-sm","gw5-icon"]       
    },
    'gw6': {
        duration: 2000,
        bearing: 0,
        center: [ 13.628519235561562, 10.885772138223762],
        zoom: 13.5,
        pitch: 50,
        text:"“By Friday, I was in Madagali and by Saturday morning, the boys attacked.”"
    },
    'md1': {
        duration: 2000,
        bearing: 0,
        center: [ 13.6361, 10.8828],
        zoom: 14,
        pitch: 50,
    },
    'md2': {
        duration: 2000,
        bearing: 90,
        center: [ 13.628810801658558, 10.894625645699548],
        zoom: 15,
        pitch: 50,
        icon: ["bello-sm","md1-icon"],
        text:"“They came right to the district where we stayed. There is a spot where people drink alcohol, and it is right opposite my house. That is where the attack happened. We found out that some of the boys we were in the village in Gwoza with were the boys attacking.”"
    },
    'md3': {
        duration: 2000,
        bearing: 240,
        center: [ 13.628810801658559, 10.894625645699548],
        zoom: 16,
        pitch: 50,
        icon: ["md3-icon"]
    },
    'md4': {
        duration: 2000,
        bearing: 360,
        center: [13.628810801658560, 10.894625645699548],
        zoom: 17,
        pitch: 50,
        icon: ["md4-icon"]
    },
    'mk1': {
        duration: 4000,
        bearing: 0,
        center: [  13.39345111913088, 10.63227751572082],
        zoom: 10,
        pitch: 50,
        icon: ["hawwa-sm","mk1-icon"]
    },
    'mk2': {
        duration: 5000,
        bearing: 0,
        center: [ 13.39345111913088, 10.63227751572082],
        zoom: 16,
        pitch: 50
    },
    'mk3': {
        duration: 2000,
        bearing: 180,
        center: [ 13.393451119131000, 10.63227751572082],
        zoom: 16.5,
        pitch: 50,
        icon: ["mk3-icon"],
        text:"“My room was set on fire and everything in the room was burnt to ash. I left with only the clothes on my back and ran to Michiki where my parents were still living,” Hawwa remembers."
    },
    'mk4': {
        duration: 2000,
        bearing: 240,
        center: [ 13.39345111913090, 10.63227751572082],
        zoom: 17,
        pitch: 50,
    },
    'mk5': {
        duration: 2000,
        bearing: 0,
        center: [ 13.39345111913091, 10.63227751572082],
        zoom: 10,
        pitch: 50,
        icon: ["mk5-icon"]
    },
    'mk6': {
        duration: 2000,
        bearing: 0,
        center: [ 12.4151, 9.2606],
        zoom: 10,
        pitch: 50,
        icon: ["hawwa-sm","gw2-icon"]
    },
    'mubi1': {
        duration: 2000,
        bearing: 0,
        center: [ 13.2606, 10.2604],
        zoom: 10,
        pitch: 50,
        icon: ["naomi-sm"]
    },
    'mubi2': {
        duration: 2000,
        bearing: 0,
        center: [ 13.263724373015075, 10.272352042280662],
        zoom: 13,
        pitch: 50
    },
    'mubi3': {
        duration: 2000,
        bearing: 40,
        center: [13.263724373015076, 10.272352042280662],
        zoom: 14,
        pitch: 50,
        icon: ["mubi4-icon", "mubi4-icon2"],
        text:"“We saw some of them holding their children and some carrying their belongings. We thought it would not affect us, so we still stayed.” “If you see the multitude of people running, you would think the world is ending,” Naomi says."
    },
    'mubi4': {
        duration: 2000,
        bearing: 0,
        center: [13.1305, 10.5267],
        zoom: 14,
        pitch: 50,
        icon: ["naomi-sm","mubi5-icon"],
        text:"“Eventually, his ability to support us was exhausted so he told us there was a camp in Uvu. The camp in Uvu was already full when we arrived,” Naomi says, adding that they walked back to Adamawa through Gombi and then to Yola, the state’s capital."
    },
    'mubi5': {
        duration: 2000,
        bearing: 0,
        center: [ 13.1305, 10.5267],
        zoom: 10,
        pitch: 50
    }
};