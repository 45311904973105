import { StyledHeader } from "./styles/Header.styled";
import logo from '../logo-animated.svg';

function Header() {
    return ( 
        <StyledHeader>
            <img src={logo} alt="logo" />
        </StyledHeader>
    );
}

export default Header;