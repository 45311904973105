import { useRef, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Map, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import Header from '../Header';
import ScrollToTopOnRefresh from '../ScrollTopRefresh';
import { Howl } from 'howler';

import UaaCharDisplay from '../subs/UaaCharDisplay';
import IconSound from '../subs/IconSound';
import { Container, Intro, Outro, MapSideContainer, Icon, Chapter, LastChapter, PopupText } from '../styles/UnequalAccessAid.styled';

import { MetaDatas, TranSections, Chapters } from '../data/UnequalAccessAidSections';
import SocialShare from '../subs/SocialShare';

const s3bucket = process.env.REACT_APP_S3_BUCKET;

const UnequalAccessAid = () => {
    ReactGA.send({ hitType: "pageview", page: document.location.pathname, title: MetaDatas.title });

    const mapRef = useRef();
    const content = useRef();
    const intro = useRef();
    const outro = useRef();

    const mobInstruction = useRef(new Howl({
        src: `${s3bucket}/audios/unequal-access-aid/uaa-instruction-mob.mp3`,
        html5: true
    }));

    const deskInstruction = useRef(new Howl({
        src: `${s3bucket}/audios/unequal-access-aid/uaa-instruction-desk.mp3`,
        html5: true
    }));

    gsap.registerPlugin(ScrollTrigger);

    const [markers, setMarkers] = useState([{ id: 'gw1', lng: Chapters["gw1"].center[0], lat: Chapters["gw1"].center[1], icon: Chapters["gw1"].icon }]);
    const [showPopup, setShowPopup] = useState(false);

    const [UaaChar, setUaaChar] = useState(false); // For the UaaCharDisplay component

    useEffect(() => {
        gsap.to(intro.current, {autoAlpha: 0, duration: 1, scrollTrigger: {trigger: "#gw1", start: `${window.innerWidth < 768 ? "top 80%" : "top 200px"}`, end: `${window.innerWidth < 768 ? "bottom 70%" : "bottom 100px"}`, scrub: true, markers: false}});
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", updateScrollPosition, false);

        let navidatedIds = ['gw1']; // Array of chapter names that have been navigated to (used to check for duplicates before setMarkers)

        // Add new marker & chech for duplicates
        function updateMarkers(chapterName) {

            // If chapterName is already in navidatedIds, remove it and its marker (this will also serve as navigate up), then return
            if (navidatedIds.includes(chapterName)) {
                const indexRem = navidatedIds.indexOf(chapterName) + 1; // +1 to remove the next chapterName in the array
                const markerRem = navidatedIds[indexRem];

                setMarkers(prevMarkers => prevMarkers.filter(marker => marker.id !== markerRem));
                
                navidatedIds.splice(indexRem, 1);

                return;
            }

            // If chapterName is not in navidatedIds, add it and its marker
            setMarkers(prevMarkers => [
                ...prevMarkers,
                {
                    id: chapterName,
                    lng: Chapters[chapterName].center[0],
                    lat: Chapters[chapterName].center[1],
                    icon: Chapters[chapterName].icon
                }
            ]);

            navidatedIds.push(chapterName);
        }

        function showThePopup(chapterName) {
            // Get the coordinates & text of the chapter
            const coordinates = Chapters[chapterName].center;
            const description = Chapters[chapterName].text;
            const popId = `${chapterName}-popup`;

            if (description) {
                setShowPopup([coordinates, description, popId]);
            }
            else {
                setShowPopup(false);
            }
        }

        let activeChapterName = 'gw1';
        function setActiveChapter(chapterName) {
            setUaaChar(chapterName);

            if (chapterName === activeChapterName) return;
            
            if (chapterName === 'gw3') {
                gsap.to(".gw2-icon", {autoAlpha: 0, duration: 1, scrollTrigger: {trigger: "#gw3", start: `${window.innerWidth < 768 ? "top bottom" : "top 90%"}`, end: `${window.innerWidth < 768 ? "bottom 95%" : "bottom 80%"}`, scrub: true, markers: false}});
            }
            else if (chapterName === 'mk5') {
                gsap.to(".mk1-icon", {autoAlpha: 0, duration: 1, scrollTrigger: {trigger: "#mk5", start: `${window.innerWidth < 768 ? "top bottom" : "top 90%"}`, end: `${window.innerWidth < 768 ? "bottom 95%" : "bottom 80%"}`, scrub: true, markers: false}});
            }
            else if (chapterName === 'mubi5') {
                gsap.to(outro.current, {autoAlpha: 1, duration: 1, scrollTrigger: {trigger: "#mubi5", start: `${window.innerWidth < 768 ? "top 30%" : "top 15%"}`, end: `${window.innerWidth < 768 ? "bottom 5%" : "bottom 5%"}`, scrub: true, markers: false}});
            }

            mapRef.current?.flyTo(Chapters[chapterName]);

            updateMarkers(chapterName);
            showThePopup(chapterName);

            activeChapterName = chapterName;
        }

        // Check if an element is on screen
        function isElementOnScreen(id) {
            const element = document.getElementById(id);
            const bounds = element.getBoundingClientRect();
            return bounds.top < window.innerHeight && bounds.bottom > 0;
        }

        // Check & set active chapter
        function updateScrollPosition() {
            for (const chapterName in Chapters) {
                if (isElementOnScreen(chapterName)) {
                    setActiveChapter(chapterName);
                    break;
                }
            }
        }

        return () => {
            window.removeEventListener("scroll", updateScrollPosition, false);
        };
      }, []);

      function playInstruction() {
        if (window.innerWidth < 768) {
            if (mobInstruction.current.playing()) return;
            mobInstruction.current.play();
        }
        else {
            if (deskInstruction.current.playing()) return;
            deskInstruction.current.play();
        }
      }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{MetaDatas.title}</title>
                <meta name="description" content={MetaDatas.description} />
                {/* <meta name="keywords" content={MetaDatas.keywords} /> */}
                <meta name="author" content={MetaDatas.author} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@HumAngle_" />
                <meta name="twitter:creator" content="@HumAngle_" />
                <meta name="twitter:title" content={MetaDatas.title} />
                <meta name="twitter:description" content={MetaDatas.description} />
                <meta name="twitter:image" content={MetaDatas.image} />
                <meta property="og:url" content={MetaDatas.url} />
                {/* <meta property="og:type" content="article" /> */}
                <meta property="og:title" content={MetaDatas.title} />
                <meta property="og:description" content={MetaDatas.description} />
                <meta property="og:image" content={MetaDatas.image} />
                <meta property="og:image:alt" content={MetaDatas.title} />
                <meta property="og:site_name" content="HumAngle" />
                {/* <meta property="fb:app_id" content="123456789" /> */}
            </Helmet>
            <ScrollToTopOnRefresh />
            <Header />
            <Container>
                <Intro ref={intro}>
                    <p onClick={playInstruction} className='listenInstruction'><span>Listen to how to navigate the story.</span><IconSound /></p>
                    <h1>{MetaDatas.title}</h1>
                    <p>Story by {MetaDatas.author}</p>
                    <SocialShare  metaData={MetaDatas} />
                    <p className='deskInstruction'>The right way to read this report involves simultaneous viewing of the map on the left and the accompanying writeup on the right. To start your experience, please scroll down. Note the icons located on the top left, which include a play button for audio narration. Additionally, the map may be maneuvered by holding down the left mouse button and moving the cursor. If the device being used has touch screen capabilities, the map can be zoomed in and out by pinching and spreading fingers respectively. Alternatively, double clicking on a point will zoom in, while holding the shift key and double clicking will zoom out.</p>
                    <p className='mobInstruction'>The right way to read this report involves simultaneous viewing of the map and the accompanying writeup. To start your experience, please scroll down. Note the icons located on the top left, which include a play button for audio narration.</p>
                </Intro>
                <Outro ref={outro}>
                    <p>The decade-long insurgency that originated in Borno has spilled over to communities in neighbouring Adamawa where many also fled their homes to reside in camp and camp-like settings. While the poor living conditions of these people are glaring, there has not been equal access to aid and humanitarian support for them compared to their Borno counterparts. HumAngle finds out why.</p>
                    <p>To read the full story, <a href='https://humanglemedia.com/where-do-you-run-to-a-tale-of-unequal-access-to-aid-for-adamawas-idps/' rel='noreferrer' target='_blank'>click here.</a></p>
                    <SocialShare  metaData={MetaDatas} />
                </Outro>
                <UaaCharDisplay chapter={UaaChar} />
                <Map
                    ref={mapRef}
                    mapboxAccessToken={"pk.eyJ1IjoiaHVtYW5nbGUtZ2lzIiwiYSI6ImNsZHVjaG9zdzA0aHkzdm44YWJrZml1ZGUifQ.gKf9kk10y4bL_x2OvpjanA"}
                    mapStyle="mapbox://styles/humangle-gis/clf6o5bbs000u01mke9myzlel"
                    scrollZoom={false}
                    keyboard={false}
                    //style={{width: '70vw', height: '100vh'}}
                    initialViewState={{
                        longitude: 13.6884,
                        latitude: 11.0897,
                        zoom: 10,
                        pitch: 50
                    }}
                >
                    {markers.map(marker => {
                        return (
                            <Marker
                                key={marker.id}
                                longitude={marker.lng}
                                latitude={marker.lat}
                            >
                                {marker.icon ? marker.icon.map((icon, index) => {
                                    return (
                                        <div key={index} className="marker">
                                            <Icon className={icon} src={`${s3bucket}/images/unequal-access-aid/markers/${icon}.png`} alt={`${icon}`} />
                                        </div>
                                    )
                                }) : null}
                            </Marker>
                        )
                    })}
                    {showPopup ? (
                        <Popup className={`${showPopup[2]} popy`} longitude={showPopup[0][0]} latitude={showPopup[0][1]} closeButton={false} closeOnClick={false} anchor="center">
                            <PopupText>{showPopup[1]}</PopupText>
                        </Popup>
                    ) : null}
                </Map>
                <MapSideContainer ref={content}>
                    {TranSections.map(section => {
                        const TagName = section.type;
                        
                        return (
                            <Chapter key={section.id} className={section.id.substring(0, section.id.length - 1)}>
                                <section id={section.id}>
                                    {/* {JSON.stringify(markers)} */}
                                    <TagName>{section.content}</TagName>
                                </section>
                            </Chapter>
                        )
                    })}
                    <LastChapter />
                </MapSideContainer>
            </Container>
        </HelmetProvider>
    )
}

export default UnequalAccessAid;