import { React, useRef, useLayoutEffect } from 'react';
import { StyledLoader } from './styles/Loader.styled';
import gsap from 'gsap';

import logo from '../logo-animated.svg';

function Loader() {
    const logoRef = useRef();

    useLayoutEffect(() => {
        //console.log(logoRef);
        gsap.fromTo(logoRef.current, {opacity: 0, rotation: 0 }, {opacity: 0.9, duration: 2, rotation: 360, ease: "elastic"});
    }, []);

    return ( 
        <StyledLoader>
            <img src={logo} ref={logoRef} alt="logo" />
        </StyledLoader>
     );
}

export default Loader;