import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "./components/styles/Global";
import Humangle from "./components/Humangle";
import UnequalAccessAid from "./components/posts/UnequalAccessAid";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-L036Z55EJ0");

const theme = {
  colors: {
    primary: '#6EC1E4',
    secondary: '#54595F',
    text: '#1D1D1D',
    accent: '#F4F6F8',
    light: '#FFFFFF'
  },
  breakpoints: {
    mobile: '480px',
    tablet: '768px',
    desktop: '1024px'
  }
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Header />} />
        <Route path="/welcome-to-humangle" element={<Humangle />} />
        <Route path="/unequal-access-aid" element={<UnequalAccessAid />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;