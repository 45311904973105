import styled from "styled-components";

const s3bucket = "https://humangle-interactive.s3.eu-north-1.amazonaws.com";

export const StyledIntro = styled.div`
    background-image: url(${s3bucket}/images/welcome-to-humangle/5.jpg);
    background-size: cover;

    > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        height: 100svh;
        background-color: rgb(0, 84, 117, 0.95);
        color: #fff;

        > span:last-of-type {
            margin-top: auto;
        }
    }

    p {
        font-size: 2em;
        margin-top: auto;
    }

    svg {
        font-size: 1.5;
        padding: 10px;
        width: 3em;
        height: 3em;
        padding-top: 40px;
        animation: zoom-in-zoom-out 1s ease infinite;
        cursor: pointer;

        :hover {
            color: #0CB7DD;
        }
    }

    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
        50% {
            transform: scale(1.5, 1.5);
        }
        100% {
            transform: scale(1, 1);
        }
    }
`

export const StyledHumAngle = styled.div`
    color: #fff;
    display: none;
    grid-auto-flow: column;
    height: 100vh;
    height: 100svh;
    font-size: 1.5em;
    background-image: url(${s3bucket}/images/welcome-to-humangle/bg.jpg);
    background-size: cover;
    
    ::after {
        content: '';
        background-color: rgb(0, 84, 117, 0.93);
        position: absolute;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 780px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`

export const ImgWrap = styled.div`
    position: absolute;
    z-index: 4;
    width: 400px;
    height: 200px;
    left: -600px;
    top: -11vh;
    //border-radius: 50%;
    border: 5px solid;
    overflow: hidden;
    background-color: black;
    visibility: hidden;
    transform: scale(0.3);

    @media (max-width: 780px) {
        width: 200px;
        height: 100px;
        left: -100px;
        top: 5vh;
    }

    > div, > div img {
        width: 100%;
        height: 100%;
    }

    > div img {
        object-fit: cover;
        display: block;
    }

    > div svg {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;
        visibility: hidden;
        z-index: 5;
    }
`

export const StyledVideo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100svh;
    z-index: 2;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000c2;

    > div {
        width: 1200px;
    }
`

export const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100svh;
    width: 100%;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
    background-color: #000000c2;
    font-size: 0.9em;
    line-height: 1.2em;
    visibility: hidden;
    overflow: auto;

    :nth-last-child(3) {
        align-items: inherit;
    }

    > div {
        overflow: auto;
        max-width: 800px;
        margin: 50px;
        font-size: 0.9em;
    }

    > iframe {
        width: 1200px;
        height: 680px;
        border: 0;
    }

    @media (max-width: 960px) {
        > div {
            font-size: 0.9em;
        }
    } 

    @media (max-width: 780px) {
        > div {
            font-size: 0.7em;
            line-height: 1.2em;
        }
        
        :nth-last-child(3) {
            align-items: inherit;
        }
    }

    @media (max-height: 600px) {
        :nth-last-child(3) {
            align-items: inherit;
        }
    }
`

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 1;

    h1, p {
        margin: 0;
    }

    h1 { font-size: 2em; }

    p { font-size: 1.5em; }

    
    @media (max-width: 960px) {
        h1 { font-size: 1.4em; }
        p {
            font-size: 1em;
            text-align: center;
            padding-top: 10px;
        }
    }    

    :last-child {
        justify-content: space-around;
    }

    @media (max-width: 780px) {
        :nth-last-child(2) {
            padding-top: 60px;
            //display: none;
        }

        :last-child {
            height: 70vh;
        }
    }
`

export const Card = styled.div`
    padding: 30px;;
    border: 0.5px solid;
    border-radius: 20% 5% / 2% 15%;
    background-color: ${({ bg }) => bg};
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //background-image: url("//unsplash.it/200/100");

    :hover {
        background-color: #fff;
        color: ${({ bg }) => bg};
    }

    :nth-child(1), :nth-child(4) {
        margin-left: -150px;
    }

    @media (max-width: 780px) {
        :nth-child(1), :nth-child(4) {
            margin-left: 0px;
        }
    }
`